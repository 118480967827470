import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ size, color, bgColor }) => (
  <SvgIcon
    viewBox="0 0 14 14"
    style={{
      height: size || 14,
      width: size || 14,
    }}
  >
    <g id="Icon-/-location">
      <path
        d="M1.02932 0L8.24356 0Q8.34494 0 8.44437 0.0197781Q8.5438 0.0395562 8.63746 0.0783523Q8.73112 0.117148 8.81542 0.173472Q8.89971 0.229795 8.9714 0.301481Q9.04308 0.373167 9.09941 0.45746Q9.15573 0.541754 9.19453 0.635416Q9.23332 0.729078 9.2531 0.82851Q9.27288 0.927941 9.27288 1.02932L9.27288 8.24356Q9.27288 8.34494 9.2531 8.44437Q9.23332 8.5438 9.19453 8.63746Q9.15573 8.73112 9.09941 8.81542Q9.04308 8.89971 8.9714 8.9714Q8.89971 9.04308 8.81542 9.09941Q8.73112 9.15573 8.63746 9.19453Q8.5438 9.23332 8.44437 9.2531Q8.34494 9.27288 8.24356 9.27288L1.02932 9.27288Q0.927941 9.27288 0.82851 9.2531Q0.729078 9.23332 0.635416 9.19453Q0.541754 9.15573 0.45746 9.09941Q0.373167 9.04308 0.301481 8.9714Q0.229795 8.89971 0.173472 8.81542Q0.117148 8.73112 0.0783523 8.63746Q0.0395562 8.5438 0.0197781 8.44437Q0 8.34494 0 8.24356L0 1.02932Q0 0.927941 0.0197781 0.82851Q0.0395562 0.729078 0.0783523 0.635416Q0.117148 0.541754 0.173472 0.45746Q0.229795 0.373167 0.301481 0.301481Q0.373167 0.229795 0.45746 0.173472Q0.541754 0.117148 0.635416 0.0783523Q0.729078 0.0395562 0.82851 0.0197781Q0.927941 0 1.02932 0Z"
        transform="translate(2 3.03032)"
        id="Rectangle"
        fill={color || '#D6D8DF'}
        stroke="none"
      />
      <path
        d="M0 0.25758L9.27288 0.25758"
        transform="translate(2 5.8637)"
        id="Path"
        fill="none"
        stroke={bgColor || '#FFFFFF'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 0L0.5 2.19202"
        transform="translate(8.19708 1.868616)"
        id="Path"
        fill="none"
        stroke={color || '#D6D8DF'}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 0L0.5 2.19202"
        transform="translate(4.0758 1.868616)"
        id="Path"
        fill="none"
        stroke={color || '#D6D8DF'}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);
